<template>
  <b-modal
    v-model="onControl"
    :title="monthSelected"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    scrollable
    hide-footer
    @hidden="close"
  >
    <div class="col-12 mb-2 p-0">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text text-center" id="basic-addon1"
            >Total :
          </span>
        </div>
        <money
          v-model="item.amount"
          v-bind="money"
          class="form-control text-right"
        >
        </money>
      </div>
    </div>
    <b-table
      v-if="report != 3"
      :fields="tableFields"
      :items="items"
      class="text-center"
      bordered
      no-border-collapse
      striped
      hover
      sticky-header
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(amount)="data">
        <span class="cursor-pointer" @click="openModalDetails(data.item)">
          <money
            v-model="data.item.amount"
            v-bind="bindMoney"
            class="without-span"
            :class="validateClassAmount(month)"
            :style="isDarkSkin ? 'color: white' : ''"
            disabled
          />
        </span>
      </template>
      <template #footer>
        <span>"sd</span>
      </template>
    </b-table>
    <div v-else>
      <b-table-simple
        hover
        small
        caption-top
        responsive
        class="table table-bordered table-responsive"
      >
        <b-tbody>
          <b-tr>
            <b-th colspan="2" style="font-size: 16px">Positive Balance</b-th>
            <b-th class="text-center" style="font-size: 14px">Amount</b-th>
          </b-tr>
          <template v-for="(item, key) in items.payments">
            <b-tr
              :key="key + 'positive'"
              v-if="key != 'Total' && item > 0"
              class="d-none"
              :ref="'trPositive'"
            >
              <b-td colspan="2" class="text-left">
                <span class="alignTitle"> {{ key }}: </span>
              </b-td>
              <b-td class="text-center">
                <span> $ {{ item | currency }} </span>
              </b-td>
            </b-tr>
          </template>
          <b-tr>
            <b-td class="td-left text-left">
              <span
                v-if="items.payments != undefined && items.payments.Total > 0"
                ref="btnShowPositive"
                class="text-primary cursor-pointer"
                @click="showDetails('Positive', 'show')"
              >
                Show More Details
                <feather-icon class="text-success" icon="ChevronsDownIcon" />
              </span>

              <span
                ref="btnHidePositive"
                class="text-primary cursor-pointer"
                style="display: none"
                @click="showDetails('Positive', 'hide')"
              >
                Hide Details
                <feather-icon class="text-danger" icon="ChevronsUpIcon" />
              </span>
            </b-td>
            <b-td class="td-left text-right"> Total: </b-td>
            <b-td class="text-center">
              $
              {{
                (items.payments != undefined ? items.payments["Total"] : 0)
                  | currency
              }}
            </b-td>
          </b-tr>
          <b-th colspan="3" class="td-left text-right" height="25px"> </b-th>
          <b-tr>
            <b-th colspan="2" style="font-size: 16px">Negative Balance</b-th>
            <b-th class="text-center" style="font-size: 14px">Amount</b-th>
          </b-tr>
          <template v-for="(item, key) in items.charges">
            <b-tr
              :key="key + 'negative'"
              v-if="key != 'Total' && item > 0"
              class="d-none"
              :ref="'trNegative'"
            >
              <b-td colspan="2" class="text-left">
                <span class="alignTitle"> {{ key }}: </span>
              </b-td>
              <b-td class="text-center">
                <span> $ {{ item | currency }} </span>
              </b-td>
            </b-tr>
          </template>
          <b-tr>
            <b-td class="td-left text-left">
              <span
                v-if="items.charges != undefined && items.charges.Total > 0"
                :ref="'btnShowNegative'"
                class="text-primary cursor-pointer"
                @click="showDetails('Negative', 'show')"
              >
                Show More Details
                <feather-icon class="text-success" icon="ChevronsDownIcon" />
              </span>

              <span
                ref="btnHideNegative"
                class="text-primary cursor-pointer"
                style="display: none"
                @click="showDetails('Negative', 'hide')"
              >
                Hide Details
                <feather-icon class="text-danger" icon="ChevronsUpIcon" />
              </span>
            </b-td>
            <b-td class="td-left text-right"> Total: </b-td>
            <b-td class="text-center">
              $
              {{
                (items.charges != undefined ? items.charges["Total"] : 0)
                  | currency
              }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-table-simple
        hover
        small
        caption-top
        responsive
        class="table table-bordered table-responsive mt-2"
      >
        <b-tbody>
          <b-tr>
            <b-th colspan="2" style="font-size: 14px">Before Month</b-th>
            <b-th class="text-center" style="font-size: 14px">Amount</b-th>
          </b-tr>
          <b-tr>
            <b-td colspan="2" class="td-left text-right">
              Positive Balance:
            </b-td>
            <b-td class="text-center">
              $
              {{
                (items.sum_before_month_payment != undefined
                  ? items.sum_before_month_payment
                  : 0) | currency
              }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="2" class="td-left text-right">
              Negative Balance:
            </b-td>
            <b-td class="text-center">
              $
              {{
                (items.sum_before_month_charge != undefined
                  ? items.sum_before_month_charge
                  : 0) | currency
              }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </b-modal>
</template>

<script>
import { Money } from "v-money";
import ReportsService from "@/views/commons/components/reports/reports.service";
export default {
  components: {
    Money,
  },
  props: {
    report: null,
    month: null,
    year: null,
    item: null,
    filtersYear: null,
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      items: [],
      bindMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      tableFields: [
        {
          key: "type",
          label: "Type",
          sortable: true,
          tdClass: "text-center",
        },
        {
          key: "amount",
          label: "AMOUNT",
          sortable: true,
          tdClass: "text-center",
        },
      ],
      money: {
        decimal: ",",
        prefix: "$ ",
        thousands: ".",
        precision: 2,
        maxlength: 16,
      },
    };
  },
  computed: {
    monthSelected() {
      return `${this.monthNames[this.month - 1]} - ${this.year}`;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.onControl = true;
    await this.getDetails();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getDetails() {
      try {
        this.addPreloader();
        this.isBusy = true;
        const amount = parseFloat(this.item.amount);
        if ((amount > 0 && this.report != 3) || this.report == 3) {
          this.overlay = true;
          this.mondalDetailsMonthSelected = this.month;
          const numDays = new Date(this.year, this.month, 0).getDate();
          const dateFromSettlements = `${this.filtersYear}-${this.month}-01`;
          const dateToSettlements = `${this.filtersYear}-${this.month}-${numDays}`;
          const dateFrom = `${this.filtersYear}-01-01`;
          const dateTo = `${this.filtersYear}-12-31`;
          const reportP = this.report + 1;
          const params = {
            from: dateFromSettlements,
            report: reportP,
            month_current: `${this.filtersYear}-${this.month}`,
            to: dateToSettlements,
            type: this.report + 1,
          };
          const data = await ReportsService.getReportDetailsGeneralBalance(
            params
          );
          if (data.status === 200) {
            this.items = data.data;
            this.isBusy = false;
          }
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    validateClassAmount(item) {
      const amount = parseFloat(item.amount);
      if (amount > 0) {
        if (this.isDarkSkin) {
          return "class-positive-amount-light";
        }
        return "class-positive-amount-dark";
      }
    },
    showDetails(index, action) {
      this.$refs[`tr${index}`].map((item, index) => {
        // if (index > 3) {
        item.$el.classList.toggle("d-none");
        // }
      });

      if (action == "show") {
        this.$refs[`btnShow${index}`].style.display = "none";
        this.$refs[`btnHide${index}`].style.display = "block";
      } else {
        this.$refs[`btnShow${index}`].style.display = "block";
        this.$refs[`btnHide${index}`].style.display = "none";
      }
    },
    openModalDetails(item) {
      if (item.amount > 0) {
        this.$emit("openModalDetails", item, 4, this.moth, this.year);
      }
    },
    
  },
};
</script>

<style scoped>
.input-group > .input-group-prepend {
  flex: 0 0 35%;
}
.input-group .input-group-text {
  width: 100%;
}
</style>
