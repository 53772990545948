import { amgApi } from '@/service/axios'

class Reports {
    async getPaymentReport(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/payment-report', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getUserPrograms(params) {
        try {
            const { data, status } = await amgApi.post('/commons/get-user-programs', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getPrograms() {
        try {
            const { data, status } = await amgApi.get('/management/charge-back/programs')
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getPaymentReportDetails(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/payment-report-details', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async generateReport(body) {
        try {
            const { data, status } = await amgApi.post('/management/reports/generate-report', body)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async generateReportGlobalIncome(body) {
        try {
            const { data, status } = await amgApi.post('/management/reports/generate-report-global-income', body)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getGlobalIncomeReport(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/get-global-income-report', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getGlobalIncomeReportDetails(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/get-global-income-report-details', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getGlobalReportChargesDetails(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/get-global-report-charges-details', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async searchGlobalReportChargesDetails(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/search-global-report-charges-details', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getGlobalReportPaidsDetails(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/get-global-report-paids-details', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async searchGlobalReportPaidsDetails(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/search-global-report-paids-details', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getPaymentStatusReport(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/get-payment-status-report', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async getReportVsPayment(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/get-report-vs-payment', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async generateVsPayment(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/generate-vs-payment', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }

    async generateVsPaymentExcel(params) {
        try {
            const data = await amgApi.post('/management/reports/generate-vs-payment-excel', params, { responseType: 'blob' })
            return data
        } catch (error) {
            throw error
        }
    }

    async graphicVsPayment(params) {
        try {
            const { data, status } = await amgApi.post('/management/reports/graphic-vs-payment', params)
            return { data, status }
        } catch (error) {
            throw error
        }
    }
    //general balance
    async getReportGeneralBalance(params) {
        try {
            const data = await amgApi.post('/debt-solution/reports/get-report', params)
            return data
        } catch (error) {
            throw error
        }
    }
    async getReportDetailsGeneralBalance(params) {
        try {
            const data = await amgApi.post('/debt-solution/reports/get-report-details', params)
            return data
        } catch (error) {
            throw error
        }
    }
    async generateReportGeneralBalance(params) {
        try {
            const data = await amgApi.post('/debt-solution/reports/generate-report', params)
            return data
        } catch (error) {
            throw error
        }
    }

    async getDateDeploymentReport(params) {
        try {
            const data = await amgApi.post('/management/reports/get-date-deployment-report', params)
            return data
        } catch (error) {
            throw error
        }
    }
}

export default new Reports()
