<template>
  <b-modal
    v-model="onControl"
    :title="monthSelected"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    scrollable
    hide-footer
    @hidden="close"
  >
    <div class="col-12 mb-2 p-0">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text text-center" id="basic-addon1"
            >Total :
          </span>
        </div>
        <money
          v-model="item.amount"
          v-bind="money"
          class="form-control text-right"
        >
        </money>
      </div>
    </div>

    <b-table
      :fields="tableFields"
      :items="items"
      class="text-center"
      bordered
      no-border-collapse
      striped
      hover
      sticky-header
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(client)="data">
        <router-link
          target="_blank"
          :class="textLink"
          v-if="moduleId === 5"
          :to="{
            name: 'debt-solution-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          >{{ data.item.client }}</router-link
        >
        <span v-else :class="textLink">{{ data.item.client }}</span>
      </template>
      <template #cell(amount)="data">
        <span> $ {{ data.value | currency }} </span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { Money } from "v-money";
import ReportsService from "@/views/commons/components/reports/reports.service";

export default {
  components: {
    Money,
  },
  props: {
    report: null,
    month: null,
    year: null,
    item: null,
    filtersYear: null,
    operation: null,
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      items: [],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      tableFields: [
        {
          key: "account",
          label: "ACCOUNT",
          sortable: true,
          tdClass: "text-center",
        },
        {
          key: "client",
          label: "NAME",
          sortable: true,
          tdClass: "text-center",
        },
        {
          key: "amount",
          label: "AMOUNT",
          sortable: true,
          tdClass: "text-center",
        },
      ],
      money: {
        decimal: ",",
        prefix: "$ ",
        thousands: ".",
        precision: 2,
        maxlength: 16,
      },
    };
  },
  computed: {
    monthSelected() {
      return `${this.monthNames[this.month - 1]} - ${this.year}`;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.onControl = true;
    await this.getDetails();
    // }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getDetailsByClient() {
      try {
        this.addPreloader();
        const numDays = new Date(this.year, this.month, 0).getDate();
        const dateFromSettlements = `${this.filtersYear}-${this.month}-01`;
        const dateToSettlements = `${this.filtersYear}-${this.month}-${numDays}`;
        const dateFrom = `${this.filtersYear}-01-01`;
        const dateTo = `${this.filtersYear}-12-31`;
        const reportP = this.report + 1;
        const params = {
          from: dateFromSettlements,
          report: reportP,
          month_current: `${this.filtersYear}-${this.month}`,
          to: dateToSettlements,
          type: this.report + 1,
          operation: this.operation,
        };
      } catch (error) {
      } finally {
        this.removePreloader();
      }
    },
    async getDetails() {
      try {
        this.addPreloader();
        this.isBusy = true;
        
        const amount = parseFloat(this.item.amount);
        if (amount > 0) {
          this.overlay = true;
          this.mondalDetailsMonthSelected = this.month;
          const numDays = new Date(this.year, this.month, 0).getDate();
          const dateFromSettlements = `${this.filtersYear}-${this.month}-01`;
          const dateToSettlements = `${this.filtersYear}-${this.month}-${numDays}`;
          const dateFrom = `${this.filtersYear}-01-01`;
          const dateTo = `${this.filtersYear}-12-31`;
          const reportP = this.report + 1;
          const params = {
            from: dateFromSettlements,
            report: reportP,
            month_current: `${this.filtersYear}-${this.month}`,
            to: dateToSettlements,
            type: this.report + 1,
            operation: this.operation,
          };
          const data = await ReportsService.getReportDetailsGeneralBalance(
            params
          );
          if (data.status === 200) {
            this.items = data.data;
            this.isBusy = false;
          }
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped>
</style>
