<template>
  <div>
    <b-col>
      <b-row class="mt-1 mb-1">
        <b-col md="6" class="d-flex align-items-center">
          <b-button
            class="btn-icon"
            variant="success"
            :disabled="timerActive"
            @click="generateReport"
          >
            <feather-icon
              v-if="!timerActive"
              icon="RefreshCwIcon"
              class="mr-1"
            />
            <b-spinner v-else small class="" />
            <span v-if="!timerActive">Refresh</span>
            <span v-else>{{ timer }}</span>
          </b-button>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-input-group class="">
                <b-form-select
                  id="year"
                  v-model="year"
                  :options="optionYears"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    class="btn-icon"
                    style="margin-right: 0.6rem"
                    @click="search"
                  >
                    <feather-icon icon="SearchIcon" size="16" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-table-simple small responsive style="white-space: nowrap" class="px-1">
      <b-thead>
        <b-tr class="text-center">
          <b-th />
          <b-th v-if="chunkMonth == 0"> JAN </b-th>
          <b-th v-if="chunkMonth == 0"> FEB </b-th>
          <b-th v-if="chunkMonth == 0"> MAR </b-th>
          <b-th v-if="chunkMonth == 0"> APR </b-th>
          <b-th v-if="chunkMonth == 0"> MAY </b-th>
          <b-th v-if="chunkMonth == 0"> JUN </b-th>
          <b-th v-if="chunkMonth == 0"> JUL </b-th>
          <b-th v-if="chunkMonth == 0"> AUG </b-th>
          <b-th v-if="chunkMonth == 0"> SEP </b-th>
          <b-th v-if="chunkMonth == 0"> OCT </b-th>
          <b-th v-if="chunkMonth == 0"> NOV </b-th>
          <b-th v-if="chunkMonth == 0"> DEC </b-th>
          <b-th v-if="chunkMonth == 0" class="color_total"> TOTAL </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in reports" :key="index" >
          <td style="text-align: left">
            <span class="text-uppercase">{{ item.name }}</span>
          </td>
          <template v-for="(month, indexChild) in item.data">
            <td
              v-if="indexChild != 12"
              :key="indexChild"
              class="cursor-pointer"
              @click="openDetailModal(index, month.num, year, month)"
            >
              <money
                v-model="month.amount"
                v-bind="bindMoney"
                class="without-span"
                :class="validateClassAmount(month, index)"
                :style="isDarkSkin ? 'color: white' : ''"
                disabled
              />
            </td>
          </template>
          <td :style="! isDarkSkin ?'background: #f3f2f7 !important; ':'background:#2F2F31 !important;'" style="opacity: 5 !important;">
            <span class="k-font-weight-bold">
              $ {{ item.data[12] | currency }}</span
            >
          </td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <modal-detail-general-balance
      v-if="detailController"
      :month="monthToSend"
      :report="reportToSend"
      :year="year"
      :item="itemToSend_d"
      :operation="operation"
      :filters-year="year"
      @close="detailController = false"
    />

    <ModalGeneralBalance
      v-if="generalBalance"
      :month="monthToSend"
      :report="reportToSend"
      :year="year"
      :item="itemToSend"
      :filters-year="year"
      @close="generalBalance = false"
      @openModalDetails="openModalDetails"
    />
  </div>
</template>
<script>
import moment from "moment";
import ReportsService from "@/views/commons/components/reports/reports.service";
import ModalDetailGeneralBalance from "@/views/commons/components/reports/tabs/general-balance/ModalDetailGeneralBalance.vue";
import ModalGeneralBalance from "@/views/commons/components/reports/tabs/general-balance/ModalGeneralBalance.vue";

export default {
  components: {
    ModalDetailGeneralBalance,
    ModalGeneralBalance,
  },
  data() {
    return {
      year: moment().format("YYYY"),
      isLoading: true,
      detailController: false,
      generalBalance: false,
      reports: [],
      isShowReport: true,
      timer: moment(10 * 1 * 1000).format("mm:ss"),
      payments: [],
      secondsTime: 10,
      minutesTime: 0o0,
      timerActive: false,
      total_authorized: [],
      total_soft: [],
      chunkMonth: 0,
      bindMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
      isBusy: false,
      modalMonthDetailsData: [],
      reportToSend: "",
      monthToSend: "",
      itemToSend: "",
      itemToSend_d:"",
      operation: null,
    };
  },
  computed: {
    optionYears() {
      const years = [];
      for (let i = 2019; i <= moment().format("YYYY"); i++) {
        years.push({ text: i, value: i });
      }
      return years;
    },
    sectionShow() {
      return this.chunkMonth === 1 ? [7, 12] : [1, 6];
    },
  },
  async created() {
    await this.search();
  },
  mounted() {
    if (moment().format("MM") >= 7) {
      this.chunkMonth = 1;
    }
  },
  methods: {
    async clearSearch() {
      this.year = moment().format("YYYY");
      await this.search();
    },

    openModalDetails(item, report, month, year) {
      this.reportToSend = report;
      this.operation = item.operation;
      this.itemToSend_d.amount = item.amount;
      this.detailController = true;
    },

    openDetailModal(report, month, year, item) {
      if (item.amount > 0) {
        this.reportToSend = report;
        this.monthToSend = month;
        if (report == 1) {
          this.itemToSend_d = item;
          this.detailController = true;

        } else {
          this.itemToSend = item;
          this.generalBalance = true;
        }
      } else {
        if (report == 3) {
          this.reportToSend = report;
          this.monthToSend = month;
          this.itemToSend = item;
          this.generalBalance = true;
        }
      }
    },
    changeChunkMonth(chunk) {
      this.chunkMonth = chunk;
    },
    async initTimer() {
      this.timer = moment(10 * 1 * 1000).format("mm:ss");
      const reloj = moment(10 * 1 * 1000);
      const timer = setInterval(async () => {
        this.timer = reloj.format("mm:ss");
        reloj.subtract(1, "seconds");
        if (this.timer === "00:00") {
          this.timerActive = false;
          await this.search();

          clearInterval(timer);
        }
      }, 1000);
    },
    async search() {
      try {
        const dateFrom = `${this.year}-01-01`;
        const dateTo = `${this.year}-12-31`;
        this.addPreloader();
        const data = await ReportsService.getReportGeneralBalance({
          from: dateFrom,
          to: dateTo,
        });
        if (data.status === 200) {
          this.reports = data.data;
          this.chunkMonth = 0;
          this.removePreloader();
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    validateTitle(item) {
      const amount = parseFloat(item.amount);
      if (amount > 0) {
        return "SEE DETAILS";
      }
      return "NO DATA FOUND";
    },
    validateClassAmount(item, repo) {
      const amount = parseFloat(item.amount);
      if ((amount > 0 && repo != 3) || (repo == 3 && amount != 0)) {
        if (this.isDarkSkin) {
          return "class-positive-amount-light";
        }
        return "class-positive-amount-dark";
      }
    },
    toogleReport() {
      this.isShowReport = !this.isShowReport;
    },

    async generateReport() {
      try {
        await this.initTimer();
        this.timerActive = true;
        const data = await ReportsService.generateReportGeneralBalance();

        if (data.status === 200) {
        }
      } catch (e) {
        this.removePreloader();
      }
    },
  },
};
</script>
<style>
.class-positive-amount-light:hover {
  color: #ff9f43 !important;
  font-weight: bold;
  cursor: pointer;
}
.class-positive-amount-dark:hover {
  color: #0090e7 !important;
  font-weight: bold;
  cursor: pointer;
}
.without-span {
  background: transparent;
  border: none;
  text-align: center;
  width: 100%;
}
.width-w {
  width: 100% !important;
}
@media (max-width: 1444px) {
  .width-w {
    width: 88% !important;
  }
}

.color_total{
  background: black !important;
}
</style>
